import React, { useRef, useEffect, useState } from "react";
import { Route, Link } from "react-router-dom";
import About from "../components/about";
import AboutPage from "../components/aboutPage";
import Discussion from "../components/discussion";
import DiscussionPage from "../components/discussionPage";
import Surveillance from "../components/surveillance";
import UrbanRnd from "./urbanRnd";

function Main() {
    const [height, setHeight] = useState("");
    const mainRef = useRef();
    const [width, setWidth] = useState("");

    useEffect(() => {
        let tempHeight = window.innerHeight;
        setHeight(tempHeight - 91);
        mainRef.current.style.height = `${height}px`;
        mainRef.current.style.maxHeight = `${height}px`;
        setWidth(window.innerWidth);

        function handleResize() {
            setHeight(window.innerHeight - 91);
            mainRef.current.style.height = `${height}px`;
            mainRef.current.style.maxHeight = `${height}px`;
            setWidth(window.innerWidth);
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, [height, width]);

    return (
        <div className="main" ref={mainRef}>
            <Route exact path="/">
                <div id="about-div" className="content">
                    <About height={height}></About>
                </div>
                <Link to="/map">
                    <div id="surv-div" className="content">
                        <p></p>
                    </div>
                </Link>

                <Discussion></Discussion>

                <Link to="/urban">
                    <div id="urban-div" className="content"></div>
                </Link>
            </Route>
            <Route exact path="/about">
                <AboutPage height={height}></AboutPage>
            </Route>
            <Route exact path="/map">
                <Surveillance height={height}></Surveillance>
            </Route>
            <Route exact path="/links">
                <DiscussionPage height={height}></DiscussionPage>
            </Route>
            <Route exact path="/urban">
                <UrbanRnd height={height} width={width}></UrbanRnd>
            </Route>
        </div>
    );
}

export default Main;
