import React, { useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";

function Menu() {
    const location = useLocation();
    const about = useRef();
    const surv = useRef();
    const disc = useRef();
    const urban = useRef();

    useEffect(() => {
        // if (location.pathname === "/about") {
        //     about.current.style.textDecoration = "line-through double";
        //     surv.current.style.textDecoration = "initial";
        //     disc.current.style.textDecoration = "initial";
        //     urban.current.style.textDecoration = "initial";
        // } else if (location.pathname === "/surv") {
        //     about.current.style.textDecoration = "initial";
        //     surv.current.style.textDecoration = "line-through double";
        //     disc.current.style.textDecoration = "initial";
        //     urban.current.style.textDecoration = "initial";
        // } else if (location.pathname === "/disc") {
        //     about.current.style.textDecoration = "initial";
        //     surv.current.style.textDecoration = "initial";
        //     disc.current.style.textDecoration = "line-through double";
        //     urban.current.style.textDecoration = "initial";
        // } else if (location.pathname === "/urban") {
        //     about.current.style.textDecoration = "initial";
        //     surv.current.style.textDecoration = "initial";
        //     disc.current.style.textDecoration = "initial";
        //     urban.current.style.textDecoration = "line-through double";
        // } else {
        //     about.current.style.textDecoration = "initial";
        //     surv.current.style.textDecoration = "initial";
        //     disc.current.style.textDecoration = "initial";
        //     urban.current.style.textDecoration = "initial";
        // }
    }, [location]);
    return (
        <div className="menu">
            <Link to="/about" className="menu-item" id="menu-about" ref={about}>
                ABOUT
            </Link>
            <Link to="/map" className="menu-item" id="menu-surv" ref={surv}>
                LATENT SURVEILLANCE MAP
            </Link>
            <Link to="/links" className="menu-item" id="menu-disc" ref={disc}>
                LINK STACK
            </Link>
            <Link to="/urban" className="menu-item" id="menu-urban" ref={urban}>
                NEW URBAN IMAGINARY
            </Link>
        </div>
    );
}

export default Menu;
