import React from "react";
import { Link } from "react-router-dom";
import Marquee from "react-double-marquee";

function Ticker() {
    return (
        <Link to="/" id="ticker-a">
            <div
                className="ticker"
                style={{
                    whiteSpace: "nowrap",
                }}
            >
                <Marquee direction="left" delay={0}>
                    <span>URBAN SURVEILLANCE BUREAU</span>
                    <span>A SEQUENCE OF WORK</span>
                    <span>ON VIEW 29/01/22 – 06/02/22</span>
                    <span>TOP PROJECT SPACE, BERLIN</span>
                    <span>URBAN SURVEILLANCE BUREAU</span>
                    <span>A SEQUENCE OF WORK</span>
                    <span>ON VIEW 29/01/22 – 06/02/22</span>
                    <span>TOP PROJECT SPACE, BERLIN</span>
                    <span>URBAN SURVEILLANCE BUREAU</span>
                    <span>A SEQUENCE OF WORK</span>
                    <span>ON VIEW 29/01/22 – 06/02/22</span>
                    <span>TOP PROJECT SPACE, BERLIN</span>
                   <span>URBAN SURVEILLANCE BUREAU</span>
                    <span>A SEQUENCE OF WORK</span>
                    <span>ON VIEW 29/01/22 – 06/02/22</span>
                    <span>TOP PROJECT SPACE, BERLIN</span>
                    <span>URBAN SURVEILLANCE BUREAU</span>
                    <span>A SEQUENCE OF WORK</span>
                    <span>ON VIEW 29/01/22 – 06/02/22</span>
                    <span>TOP PROJECT SPACE, BERLIN</span>
                    <span>URBAN SURVEILLANCE BUREAU</span>
                    <span>A SEQUENCE OF WORK</span>
                    <span>ON VIEW 29/01/22 – 06/02/22</span>
                    <span>TOP PROJECT SPACE, BERLIN</span>
                </Marquee>
            </div>
        </Link>
    );
}

export default Ticker;
