import React, { useState, useEffect } from "react";
import { Rnd } from "react-rnd";

import Img01 from "../images/urban/01.jpg";
import Img02 from "../images/urban/02.jpg";
import Img03 from "../images/urban/03.jpg";
import Img04 from "../images/urban/04.jpg";
import Img05 from "../images/urban/05.jpg";
import Img06 from "../images/urban/06.jpg";
import Img07 from "../images/urban/07.jpg";
import Img08 from "../images/urban/08.jpg";
import Img09 from "../images/urban/09.png";
import Img10 from "../images/urban/10.png";
import Img11 from "../images/urban/11.jpg";
import Img12 from "../images/urban/12.jpg";
import Img13 from "../images/urban/13.png";

function UrbanRnd(props) {
    const [z, setZ] = useState(0);

    const [img01Size, setImg01Size] = useState({
        x: 30,
        y: 30,
        height: 400,
        width: 400,
    });
    const [img02Size, setImg02Size] = useState({
        x: 60,
        y: 60,
        height: 400,
        width: 400,
    });
    const [img03Size, setImg03Size] = useState({
        x: 90,
        y: 90,
        height: 400,
        width: 400,
    });
    const [img04Size, setImg04Size] = useState({
        x: 120,
        y: 120,
        height: 400,
        width: 400,
    });
    const [img05Size, setImg05Size] = useState({
        x: 150,
        y: 150,
        height: 400,
        width: 400,
    });
    const [img06Size, setImg06Size] = useState({
        x: 180,
        y: 180,
        height: 400,
        width: 400,
    });
    const [img07Size, setImg07Size] = useState({
        x: 600,
        y: 30,
        height: 400,
        width: 400,
    });
    const [img08Size, setImg08Size] = useState({
        x: 630,
        y: 60,
        height: 400,
        width: 400,
    });
    const [img09Size, setImg09Size] = useState({
        x: 660,
        y: 90,
        height: 400,
        width: 400,
    });
    const [img10Size, setImg10Size] = useState({
        x: 690,
        y: 120,
        height: 400,
        width: 400,
    });
    const [img11Size, setImg11Size] = useState({
        x: 720,
        y: 150,
        height: 400,
        width: 400,
    });
    const [img12Size, setImg12Size] = useState({
        x: 750,
        y: 180,
        height: 400,
        width: 400,
    });
    const [img13Size, setImg13Size] = useState({
        x: 180,
        y: 180,
        height: 400,
        width: 400,
    });

    const divStyle = {
        height: `${props.height}`,
        width: `${props.width}`,
    };

    const style = {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundSize: "cover",
        cursor: "grab",
    };

    useEffect(() => {}, [img01Size, img02Size, img03Size]);

    return (
        <div className="urban" style={divStyle}>
            <Rnd
                style={{ backgroundImage: `url(${Img01})`, ...style }}
                size={{ width: img01Size.width, height: img01Size.height }}
                position={{ x: img01Size.x, y: img01Size.y }}
                bounds={"parent"}
                lockAspectRatio={true}
                onDragStop={(e, d) => {
                    setImg01Size({ x: d.x, y: d.y });
                }}
                onResizeStop={(e, direction, ref, delta, position) => {
                    setImg01Size({
                        width: ref.style.width,
                        height: ref.style.height,
                        ...position,
                    });
                }}
                onClick={(e) => {
                    e.target.style.zIndex = z + 1;
                    setZ(z + 1);
                }}
            ></Rnd>
            <Rnd
                bounds={"parent"}
                style={{ backgroundImage: `url(${Img02})`, ...style }}
                size={{ width: img02Size.width, height: img02Size.height }}
                position={{ x: img02Size.x, y: img02Size.y }}
                lockAspectRatio={true}
                onDragStop={(e, d) => {
                    setImg02Size({ x: d.x, y: d.y });
                }}
                onResizeStop={(e, direction, ref, delta, position) => {
                    setImg02Size({
                        width: ref.style.width,
                        height: ref.style.height,
                        ...position,
                    });
                }}
                onClick={(e) => {
                    e.target.style.zIndex = z + 1;
                    setZ(z + 1);
                }}
            ></Rnd>
            <Rnd
                bounds={"parent"}
                style={{ backgroundImage: `url(${Img03})`, ...style }}
                size={{ width: img03Size.width, height: img03Size.height }}
                position={{ x: img03Size.x, y: img03Size.y }}
                lockAspectRatio={true}
                onDragStop={(e, d) => {
                    setImg03Size({ x: d.x, y: d.y });
                }}
                onResizeStop={(e, direction, ref, delta, position) => {
                    setImg03Size({
                        width: ref.style.width,
                        height: ref.style.height,
                        ...position,
                    });
                }}
                onClick={(e) => {
                    e.target.style.zIndex = z + 1;
                    setZ(z + 1);
                }}
            ></Rnd>
            <Rnd
                bounds={"parent"}
                style={{ backgroundImage: `url(${Img04})`, ...style }}
                size={{ width: img04Size.width, height: img04Size.height }}
                position={{ x: img04Size.x, y: img04Size.y }}
                lockAspectRatio={true}
                onDragStop={(e, d) => {
                    setImg04Size({ x: d.x, y: d.y });
                }}
                onResizeStop={(e, direction, ref, delta, position) => {
                    setImg04Size({
                        width: ref.style.width,
                        height: ref.style.height,
                        ...position,
                    });
                }}
                onClick={(e) => {
                    e.target.style.zIndex = z + 1;
                    setZ(z + 1);
                }}
            ></Rnd>
            <Rnd
                bounds={"parent"}
                style={{ backgroundImage: `url(${Img05})`, ...style }}
                size={{ width: img05Size.width, height: img05Size.height }}
                position={{ x: img05Size.x, y: img05Size.y }}
                lockAspectRatio={true}
                onDragStop={(e, d) => {
                    setImg05Size({ x: d.x, y: d.y });
                }}
                onResizeStop={(e, direction, ref, delta, position) => {
                    setImg05Size({
                        width: ref.style.width,
                        height: ref.style.height,
                        ...position,
                    });
                }}
                onClick={(e) => {
                    e.target.style.zIndex = z + 1;
                    setZ(z + 1);
                }}
            ></Rnd>
            <Rnd
                bounds={"parent"}
                style={{ backgroundImage: `url(${Img06})`, ...style }}
                size={{ width: img06Size.width, height: img06Size.height }}
                position={{ x: img06Size.x, y: img06Size.y }}
                lockAspectRatio={true}
                onDragStop={(e, d) => {
                    setImg06Size({ x: d.x, y: d.y });
                }}
                onResizeStop={(e, direction, ref, delta, position) => {
                    setImg06Size({
                        width: ref.style.width,
                        height: ref.style.height,
                        ...position,
                    });
                }}
                onClick={(e) => {
                    e.target.style.zIndex = z + 1;
                    setZ(z + 1);
                }}
            ></Rnd>
            <Rnd
                bounds={"parent"}
                style={{ backgroundImage: `url(${Img07})`, ...style }}
                size={{ width: img07Size.width, height: img07Size.height }}
                position={{ x: img07Size.x, y: img07Size.y }}
                lockAspectRatio={true}
                onDragStop={(e, d) => {
                    setImg07Size({ x: d.x, y: d.y });
                }}
                onResizeStop={(e, direction, ref, delta, position) => {
                    setImg07Size({
                        width: ref.style.width,
                        height: ref.style.height,
                        ...position,
                    });
                }}
                onClick={(e) => {
                    e.target.style.zIndex = z + 1;
                    setZ(z + 1);
                }}
            ></Rnd>
            <Rnd
                bounds={"parent"}
                style={{ backgroundImage: `url(${Img08})`, ...style }}
                size={{ width: img08Size.width, height: img08Size.height }}
                position={{ x: img08Size.x, y: img08Size.y }}
                lockAspectRatio={true}
                onDragStop={(e, d) => {
                    setImg08Size({ x: d.x, y: d.y });
                }}
                onResizeStop={(e, direction, ref, delta, position) => {
                    setImg08Size({
                        width: ref.style.width,
                        height: ref.style.height,
                        ...position,
                    });
                }}
                onClick={(e) => {
                    e.target.style.zIndex = z + 1;
                    setZ(z + 1);
                }}
            ></Rnd>
            <Rnd
                bounds={"parent"}
                style={{ backgroundImage: `url(${Img09})`, ...style }}
                size={{ width: img09Size.width, height: img09Size.height }}
                position={{ x: img09Size.x, y: img09Size.y }}
                lockAspectRatio={true}
                onDragStop={(e, d) => {
                    setImg09Size({ x: d.x, y: d.y });
                }}
                onResizeStop={(e, direction, ref, delta, position) => {
                    setImg09Size({
                        width: ref.style.width,
                        height: ref.style.height,
                        ...position,
                    });
                }}
                onClick={(e) => {
                    e.target.style.zIndex = z + 1;
                    setZ(z + 1);
                }}
            ></Rnd>
            <Rnd
                bounds={"parent"}
                style={{ backgroundImage: `url(${Img10})`, ...style }}
                size={{ width: img10Size.width, height: img10Size.height }}
                position={{ x: img10Size.x, y: img10Size.y }}
                lockAspectRatio={true}
                onDragStop={(e, d) => {
                    setImg10Size({ x: d.x, y: d.y });
                }}
                onResizeStop={(e, direction, ref, delta, position) => {
                    setImg10Size({
                        width: ref.style.width,
                        height: ref.style.height,
                        ...position,
                    });
                }}
                onClick={(e) => {
                    e.target.style.zIndex = z + 1;
                    setZ(z + 1);
                }}
            ></Rnd>
            <Rnd
                bounds={"parent"}
                style={{ backgroundImage: `url(${Img11})`, ...style }}
                size={{ width: img11Size.width, height: img11Size.height }}
                position={{ x: img11Size.x, y: img11Size.y }}
                lockAspectRatio={true}
                onDragStop={(e, d) => {
                    setImg11Size({ x: d.x, y: d.y });
                }}
                onResizeStop={(e, direction, ref, delta, position) => {
                    setImg11Size({
                        width: ref.style.width,
                        height: ref.style.height,
                        ...position,
                    });
                }}
                onClick={(e) => {
                    e.target.style.zIndex = z + 1;
                    setZ(z + 1);
                }}
            ></Rnd>
            <Rnd
                bounds={"parent"}
                style={{ backgroundImage: `url(${Img12})`, ...style }}
                size={{ width: img12Size.width, height: img12Size.height }}
                position={{ x: img12Size.x, y: img12Size.y }}
                lockAspectRatio={true}
                onDragStop={(e, d) => {
                    setImg12Size({ x: d.x, y: d.y });
                }}
                onResizeStop={(e, direction, ref, delta, position) => {
                    setImg12Size({
                        width: ref.style.width,
                        height: ref.style.height,
                        ...position,
                    });
                }}
                onClick={(e) => {
                    e.target.style.zIndex = z + 1;
                    setZ(z + 1);
                }}
            ></Rnd>
            <Rnd
                bounds={"parent"}
                style={{ backgroundImage: `url(${Img13})`, ...style }}
                size={{ width: img13Size.width, height: img13Size.height }}
                position={{ x: img13Size.x, y: img13Size.y }}
                lockAspectRatio={true}
                onDragStop={(e, d) => {
                    setImg13Size({ x: d.x, y: d.y });
                }}
                onResizeStop={(e, direction, ref, delta, position) => {
                    setImg13Size({
                        width: ref.style.width,
                        height: ref.style.height,
                        ...position,
                    });
                }}
                onClick={(e) => {
                    e.target.style.zIndex = z + 1;
                    setZ(z + 1);
                }}
            ></Rnd>
        </div>
    );
}

export default UrbanRnd;
