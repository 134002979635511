import React, { useRef, useEffect, useState } from "react";

import Img1 from "../images/about/image_1.jpg";
import Img2 from "../images/about/image_2.jpg";
import Img3 from "../images/about/image_3.jpg";
import Img4 from "../images/about/image_4.jpg";
import Img4Extra from "../images/about/image_4_extra.jpg";
import Img5 from "../images/about/image_5.jpg";
import Img6 from "../images/map_animation.gif";
import Img7 from "../images/about/image_7.jpg";
import Img8 from "../images/about/image_8.jpg";
import Img9 from "../images/about/image_9.jpg";
import Img10 from "../images/about/image_10.gif";
import Img11 from "../images/about/image_11.gif";
import Img12 from "../images/about/image_12.png";
import Img13 from "../images/about/image_13.gif";

function AboutPage(props) {
    const aboutRef = useRef();
    const word1 = useRef();
    const word2 = useRef();
    const word3 = useRef();
    const word4 = useRef();
    const word4Extra = useRef();
    const word5 = useRef();
    const word6 = useRef();
    const word7 = useRef();
    const word8 = useRef();
    const word9 = useRef();
    const word10 = useRef();
    const word11 = useRef();
    const word12 = useRef();
    const word13 = useRef();

    const image1 = useRef();
    const image2 = useRef();
    const image3 = useRef();
    const image4 = useRef();
    const image4Extra = useRef();
    const image5 = useRef();
    const image6 = useRef();
    const image7 = useRef();
    const image8 = useRef();
    const image9 = useRef();
    const image10 = useRef();
    const image11 = useRef();
    const image12 = useRef();
    const image13 = useRef();
    const [width, setWidth] = useState("");

    function showImage(word, image) {
        let rect = word.current.getBoundingClientRect();
        image.current.style.display = "inline-block";
        image.current.style.visibility = "visible";

        if (
            rect.left + rect.width + image.current.offsetWidth >
            window.innerWidth
        ) {
            image.current.style.left = `${-image.current.offsetWidth}px`;
        } else {
            image.current.style.left = `${10 + word.current.offsetWidth}px`;
        }
        if (rect.top < window.innerHeight / 2) {
            image.current.style.top = `${40}px`;
        } else {
            image.current.style.top = `${-image.current.offsetHeight}px`;
        }
    }

    function hideImage(image) {
        image.current.style.visibility = "hidden";
        image.current.style.display = "none";
    }

    useEffect(() => {
        function handleResize() {
            setWidth(window.innerWidth);
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, [width]);
    return (
        <div className="about page">
            <div className="about-text" ref={aboutRef}>
                <p>
                    Most of the world human population lives in cities nowadays;
                    we live in cities as commodities; cities are contemporary
                    tools for/of consumption. Power structures treat us as
                    consumers; social belonging and visibility is defined by
                    one's own consumption power.
                </p>
                <p>
                    Neoliberal urbanisation policies promote the free market and
                    privatise the global and local doing and undoing of cities.
                    The state governs through a managerial model and administers
                    the city as a corporation. The market becomes the most
                    influential institution in promoting life quality from a
                    financial perspective through the delusion of “the good
                    life”. In this process, the state needs the market, and the
                    market the state. The state depends on the market to produce
                    wealth and services, and the market depends on the state to
                    create an entrepreneurial competitive environment through
                    neoliberal policies. The principle of cooperation between
                    the state and the market lays at the core of neoliberal
                    governance.
                </p>
                <p>
                    The city is consumed and experienced as entertainment by
                    those who can afford it, space becomes the privileged
                    instrument for capital to unfold, to happen through the
                    social realm. In the digitalized city, also citizens are
                    commodified, becoming in turn the "privileged instrument" of
                    capital by using{" "}
                    <span
                        ref={word1}
                        onMouseEnter={(e) => showImage(word1, image1)}
                        onMouseLeave={(e) => hideImage(image1)}
                    >
                        “smart” tools'
                        <img ref={image1} src={Img1} alt=".."></img>
                    </span>{" "}
                    and allowing data extraction. Smartphones and smart cities
                    are new conquerors of the urban space, feeding capitalism
                    through the hidden scripts in the "Terms & Conditions" of
                    digital platforms.
                </p>
                <p>
                    Within this system of value, which uses cities as a
                    territory for an automated gig economy and{" "}
                    <span
                        ref={word2}
                        onMouseEnter={(e) => showImage(word2, image2)}
                        onMouseLeave={(e) => hideImage(image2)}
                    >
                        behavioural marketing
                        <img ref={image2} src={Img2} alt=".."></img>
                    </span>
                    , what sorts of deductions are possible about the future and
                    the cities of the future? If in the analog city, citizens
                    actively take part and develop the image of the city
                    creatively, which psychogeographies emerge when we use
                    digital devices to mediate our urban whereabouts?
                </p>
                <p>
                    With mass behavioural manipulation, the perception mode of
                    the physical environment constantly changes. The visual
                    landscape of the city is monetized, becoming a commodity to
                    be acquired by tech companies or protected through{" "}
                    <span
                        ref={word3}
                        onMouseEnter={(e) => showImage(word3, image3)}
                        onMouseLeave={(e) => hideImage(image3)}
                    >
                        pixelation right
                        <img ref={image3} src={Img3} alt=".."></img>
                    </span>
                    .
                </p>
                <p>
                    The trendy term nowadays,{" "}
                    <span
                        ref={word4}
                        onMouseEnter={(e) => showImage(word4, image4)}
                        onMouseLeave={(e) => hideImage(image4)}
                    >
                        Smart City
                        <img ref={image4} src={Img4} alt=".."></img>
                    </span>
                    , will eventually emerge as a proper entity, colonising
                    imaginaries, behaviours and economies through data
                    extraction, merging virtual and physical dimensions: in the
                    face of this, new strategies and imaginaries are needed to
                    decolonise cities under this data sovereignty. In the{" "}
                    <span
                        ref={word4Extra}
                        onMouseEnter={(e) => showImage(word4Extra, image4Extra)}
                        onMouseLeave={(e) => hideImage(image4Extra)}
                    >
                        technocapitalist
                        <img ref={image4Extra} src={Img4Extra} alt=".."></img>
                    </span>{" "}
                    urban realm, the right to the city needs to include the
                    right to data.
                </p>
                <p>
                    In this context,{" "}
                    <span
                        ref={word5}
                        onMouseEnter={(e) => showImage(word5, image5)}
                        onMouseLeave={(e) => hideImage(image5)}
                    >
                        Hide & Seek
                        <img ref={image5} src={Img5} alt=".."></img>
                    </span>{" "}
                    is a project which looks at mapping technologies, digital
                    image production, and data collection processes to
                    understand how surveillance systems and data-driven
                    structures influence place-making, heritization and
                    memory-building. In this way, the project asks how
                    technology will affect the virtual imaginaries of future
                    cities—and what kind of (visual) resistance could emerge to
                    counter them.
                </p>
                <p>
                    Within the larger project,{" "}
                    <span
                        ref={word6}
                        onMouseEnter={(e) => showImage(word6, image6)}
                        onMouseLeave={(e) => hideImage(image6)}
                    >
                        Latent Surveillance Map
                        <img ref={image6} src={Img6} alt=".."></img>
                    </span>{" "}
                    shows cell towers around the world, and contextualizes the
                    information with articles and discussions on urban data
                    sovereignty and surveillance systems. With the advent of
                    computers and the beginning of digital mapping processes,
                    land census, and the different{" "}
                    <span
                        ref={word7}
                        onMouseEnter={(e) => showImage(word7, image7)}
                        onMouseLeave={(e) => hideImage(image7)}
                    >
                        calibration systems
                        <img ref={image7} src={Img7} alt=".."></img>
                    </span>{" "}
                    emerged in 60s, a virtual image of the world started to be
                    composed. While the earth becomes a weightless object
                    assembled from satellite images, GIS data, aerial photos,{" "}
                    <span
                        ref={word8}
                        onMouseEnter={(e) => showImage(word8, image8)}
                        onMouseLeave={(e) => hideImage(image8)}
                    >
                        a globe to spin by clicking on a mouse
                        <img ref={image8} src={Img8} alt=".."></img>
                    </span>
                    , the technological tools which make this possible leave
                    physical traces on earth. Such entanglement requires us to
                    think about infrastructure, urban heritage, and architecture
                    together.
                </p>
                <p>
                    In the urban realm, cell towers and GSM operators are
                    building new kinds of territories and political landscapes.
                    The infrastructure of location-based services and mobile
                    phone{" "}
                    <span
                        ref={word9}
                        onMouseEnter={(e) => showImage(word9, image9)}
                        onMouseLeave={(e) => hideImage(image9)}
                    >
                        networks
                        <img ref={image9} src={Img9} alt=".."></img>
                    </span>{" "}
                    , such as 5G, is an emergent terrain of competition between
                    countries and a new geopolitical power game.
                </p>
                <p>
                    On a larger scale, such visible and invisible infrastructure
                    can legitimize implanting security and tracking tools in
                    cities, accelerating processes of dispossession and
                    gentrification. Latent Surveillance Map makes the
                    surreptitious infrastructure of the digital
                    mapping/surveillance of the world appear as physical
                    entities distributed in the urban space.
                </p>
                <p>
                    The Latent Surveillance Map will be made public through the{" "}
                    <span
                        ref={word10}
                        onMouseEnter={(e) => showImage(word10, image10)}
                        onMouseLeave={(e) => hideImage(image10)}
                    >
                        Urban Surveillance Bureau
                        <img ref={image10} src={Img10} alt=".."></img>
                    </span>
                    , a fictional bureau which will move around the city in
                    different locations before and during the exhibition. It
                    will consist in a mobile outdoor installation, an assemblage
                    of{" "}
                    <span
                        ref={word11}
                        onMouseEnter={(e) => showImage(word11, image11)}
                        onMouseLeave={(e) => hideImage(image11)}
                    >
                        different surveillance tools
                        <img ref={image11} src={Img11} alt=".."></img>
                    </span>
                    , antennas, screens, and camouflage surfaces. The bureau
                    performs as a mock-up of a governamental infopoint to ask
                    how is it possible to hide within the system and what should
                    we do to empower dissident movements.{" "}
                    <span
                        ref={word12}
                        onMouseEnter={(e) => showImage(word12, image12)}
                        onMouseLeave={(e) => hideImage(image12)}
                    >
                        Urban Surveillance Bureau
                        <img ref={image12} src={Img12} alt=".."></img>
                    </span>{" "}
                    in Berlin mimics bureaucratic gestures and administrative
                    practices to instead offer new and dissident urban
                    imaginaries through walking tours and counter mapping. The
                    Bureau makes visible the infrastractures of digital
                    extraction concealed within the topography of the city and
                    instructs participants on how they allow corporations to
                    access their data. Throughout the exhibition and
                    intervention period, information will be collected from the
                    Bureau’s activity as a speculative exercise on data mining
                    and its potential to be turned into{" "}
                    <span
                        ref={word13}
                        onMouseEnter={(e) => showImage(word13, image13)}
                        onMouseLeave={(e) => hideImage(image13)}
                    >
                        collective narratives
                        <img ref={image13} src={Img13} alt=".."></img>
                    </span>{" "}
                    reshaping the imaginaries of future cities.
                </p>
            </div>
        </div>
    );
}

export default AboutPage;
