import React from "react";
import { Link } from "react-router-dom";

function Discussion() {
    return (
        <div id="discussion-div" className="content">
            <p>
                The Urban Surveillance Bureau makes visible the infrastructures
                of digital extraction concealed within the topography of the
                city and instructs participants on how they allow corporations
                to access their data. To do that, the Bureau collects
                information as link stack. The data will be collected from
                various sources such as research, news, articles, podcasts,
                videos and projects that are inspiring for the project and worth
                to browse it.
            </p>
            <p>
                Please feel free to share your links around urban data
                sovereignty{" "}
                <a href="mailto:contact@urban-surveillance.com">
                    contact@urban-surveillance.com
                </a>{" "}
            </p>

            <p>
                <Link to="/links">Access -{">"}</Link>
            </p>
        </div>
    );
}

export default Discussion;
