import React from "react";

import AliceCarousel from "react-alice-carousel";
import "../../src/alice-carousel.scss";

import Img0 from "../images/surv/image_0.jpg";
import Img1 from "../images/surv/image_1_copy.jpg";
import Img2 from "../images/surv/image_2.jpg";
import Img3 from "../images/surv/image_3.jpg";
import Img4 from "../images/surv/image_4.jpg";
import Img5 from "../images/surv/image_5.jpg";
import Img6 from "../images/surv/image_6.jpg";
import Img7 from "../images/surv/image_7.jpg";
import Img8 from "../images/surv/image_8.jpg";
import Img9 from "../images/surv/image_9.jpg";
import Img10 from "../images/surv/image_10.jpg";

function Surveillance(props) {
    const divStyle = {
        height: `${props.height}px`,
        maxHeight: `${props.height}px`,
    };

    const handleDragStart = (e) => e.preventDefault();

    const items = [
        <img
            src={Img0}
            onDragStart={handleDragStart}
            className="slide-img"
            alt=".."
            style={divStyle}
        ></img>,
        <img
            src={Img1}
            onDragStart={handleDragStart}
            className="slide-img"
            alt=".."
            style={divStyle}
        ></img>,
        <img
            src={Img2}
            onDragStart={handleDragStart}
            className="slide-img"
            alt=".."
            style={divStyle}
        ></img>,
        <img
            src={Img3}
            onDragStart={handleDragStart}
            className="slide-img"
            alt=".."
            style={divStyle}
        ></img>,
        <img
            src={Img4}
            onDragStart={handleDragStart}
            className="slide-img"
            alt=".."
            style={divStyle}
        ></img>,
        <img
            src={Img5}
            onDragStart={handleDragStart}
            className="slide-img"
            alt=".."
            style={divStyle}
        ></img>,
        <img
            src={Img6}
            onDragStart={handleDragStart}
            className="slide-img"
            alt=".."
            style={divStyle}
        ></img>,
        <img
            src={Img7}
            onDragStart={handleDragStart}
            className="slide-img"
            alt=".."
            style={divStyle}
        ></img>,
        <img
            src={Img8}
            onDragStart={handleDragStart}
            className="slide-img"
            alt=".."
            style={divStyle}
        ></img>,
        <img
            src={Img9}
            onDragStart={handleDragStart}
            className="slide-img"
            alt=".."
            style={divStyle}
        ></img>,
        <img
            src={Img10}
            onDragStart={handleDragStart}
            className="slide-img"
            alt=".."
            style={divStyle}
        ></img>,
    ];

    return (
        <div className="surveillance page" style={divStyle}>
            <AliceCarousel
                mouseTracking
                items={items}
                infinite={true}
            ></AliceCarousel>
        </div>
    );
}

export default Surveillance;
