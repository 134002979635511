import React from "react";
import "./App.css";
import Ticker from "../src/components/ticker";
import Main from "../src/components/main";
import Menu from "../src/components/menu";
import "./App.css";

function App() {
    return (
        <div className="App">
            <Ticker></Ticker>
            <Menu></Menu>
            <div className="main-container">
                <Main></Main>
            </div>
        </div>
    );
}

export default App;
