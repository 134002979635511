import React from "react";
import { discussionList } from "../discussionData/discussion";

function DiscussionPage() {
    const discList = discussionList.map((each, index) => (
        <div key={index}>
            {each.img ? (
                <a
                    href={each.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    onMouseEnter={(e) => {
                        e.target.querySelector("img").style.visibility =
                            "visible";
                    }}
                    onMouseLeave={(e) => {
                        e.target.querySelector("img").style.visibility =
                            "hidden";
                    }}
                >
                    {" "}
                    <img
                        className="disc-img"
                        src={require(`../discussionData/discImages/${each.img}`)}
                        alt=".."
                    />
                    {each.txt}
                </a>
            ) : (
                <a href={each.link} target="_blank" rel="noopener noreferrer">
                    {each.txt}
                </a>
            )}
        </div>
    ));

    return (
        <div className="discussion-page page">
            <div className="links-main">
                <p>Links</p>

                {discList}
            </div>
        </div>
    );
}

export default DiscussionPage;
