import React from "react";
import { Link } from "react-router-dom";

function About(props) {
    return (
        <div className="about-container">
            <div className="about" style={{ minHeight: props.height }}>
                <div>
                    <p>
        
Urban Surveillance Bureau<br></br>
A Sequence of work in process <p>                    </p>

Exhibition on view: 29.01.22 – 06.02.22                    <p>                    </p>
Visiting hours: 14:00 – 19:00 and by appointment                    <p>                    </p>
<a
                            href="http://www.top-ev.de/exhibition/urban-surveillance-bureau-a-sequence-of-work-in-process-by-yelta-kom/"
                            target="__blank"
                            referrerPolicy="no-referrer"
                        >
TOP project space                        </a>, Schillerpromenade 4, 12049 Berlin                     <p>                    </p>

Part of transmediale / <a
                            href="http://vorspiel.berlin/"
                            target="__blank"
                            referrerPolicy="no-referrer"
                        >
Vorspiel                      </a>, 2022  <p>                    </p>
    
                        Most of the world human population lives in cities
                        nowadays; we live in cities as commodities; cities are
                        contemporary tools for/of consumption. Power structures
                        treat us as consumers; social belonging and visibility
                        is defined by one's own consumption power. Neoliberal
                        urbanisation policies promote the free market and
                        privatise the global and local doing and undoing of
                        cities. The city is consumed and experienced as
                        entertainment by those who can afford it, space becomes
                        the privileged instrument for capital to unfold, to
                        happen through the social realm. In the digitalized
                        city, also citizens are commodified, becoming in turn
                        the "privileged instrument" of capital by using “smart”
                        tools' and allowing data extraction.
                    </p>
                    <p>
                        <a
                            href="https://forecast-platform.com/yelta-koem/"
                            target="__blank"
                            referrerPolicy="no-referrer"
                        >
                            Hide & Seek
                        </a>{" "}
                        is a project, supported by the{" "}
                        <a
                            href="https://forecast-platform.com/"
                            target="__blank"
                            referrerPolicy="no-referrer"
                        >
                            Forecast Platform
                        </a>{" "}
                        in the category{" "}
                        <a
                            href="https://forecast-platform.com/mentor/evgeny-morozov/"
                            target="__blank"
                            referrerPolicy="no-referrer"
                        >
                            Dissecting Technocapitalism
                        </a>{" "}
                        , with mentorship led by{" "}
                        <a
                            href="http://evgenymorozov.com/"
                            target="__blank"
                            referrerPolicy="no-referrer"
                        >
                            Evgeny Morozov
                        </a>
                        , which looks at mapping technologies, digital image
                        production, and data collection processes to understand
                        how surveillance systems and data-driven structures
                        influence place-making, heritization and
                        memory-building. In this way, the project asks how
                        technology will affect the virtual imaginaries of future
                        cities—and what kind of resistance could emerge
                        to counter them.
                    </p>
                    <p>
                        <Link to="/about">Read more -{">"}</Link>
                    </p>
                </div>

                <div className="credits">
                    <p>
                        Hide&Seek is developed by{" "}
                        <a
                            href="http://yeltakom.info/"
                            target="__blank"
                            referrerPolicy="no-referrer"
                        >
                            Yelta Köm
                        </a>{" "}
                        with support of Forecast Platform and mentorship of
                        Evgeny Morozov.
                    </p>
                    <p>
                        Thanks for the support and their time and contribution
                        to the project, Evgeny Morozov, fellow friends Joana
                        Moll and PostRational, collaborators for Urban
                        Surveillance Bureau Website (Graphic Design :{" "}
                        <a
                            href="https://www.behance.net/ipekerdol"
                            target="__blank"
                            referrerPolicy="no-referrer"
                        >
                            İpek Erdöl
                        </a>{" "}
                        , Web Development :{" "}
                        <a
                            href="http://www.candaserdener.info"
                            target="__blank"
                            referrerPolicy="no-referrer"
                        >
                            Candaş Erdener
                        </a>
                        , Editing : Viola Castellano) and Freo Majer, Patrick
                        Liwitzki and Forecast Team
                                            </p>

                        <p>
                          Data sources & Applications : OpenCellID, Google Street View, RunwayML, StyleGAN
                    </p>
                </div>
            </div>
        </div>
    );
}

export default About;
