export const discussionList = [
    {
        link: "https://www.e-flux.com/journal/59/61130/operational-images/",
        txt: "Operational Images",
        // img: "img0.jpg",
    },
    {
        link:
            "https://placesjournal.org/article/a-city-is-not-a-computer/?gclid=EAIaIQobChMI8sTjyqnV6gIVBrLVCh3d7gu5EAAYAyAAEgJYQfD_BwE&amp;cn-reloaded=1",
        txt: "A City Is Not a Computer",
        img: "img1.jpg",
    },
    {
        link: "https://www.verdict.co.uk/smart-cities-timeline/",
        txt: "History of smart cities: Timeline",
        img: "img2.jpg",
    },
    {
        link:
            "https://medium.com/design-matters-4/a-new-approach-to-designing-smart-cities-b33443a4d968",
        txt: "A New Approach to Designing Smart Cities",
        img: "img3.jpg",
    },
    {
        link: "https://cyberfeminismindex.com/",
        txt: "“Cyberfeminism Index",
        // img: "img4.jpg",
    },
    {
        link:
            "https://www.visualcapitalist.com/visualizing-all-of-earths-satellites/",
        txt: "Visualizing All of Earth's Satellites: Who Owns Our Orbit?",
        img: "img6.jpg",
    },
    {
        link: "https://archive.org/details/BehindTheSmartCityEvgenyMorozov",
        txt: "Behind The Smart City Evgeny Morozov",
        // img: "img7.jpg",
    },
    {
        link: "https://janavirgin.com/INANIMATESPECIES/",
        txt: "Inanimate Species",

        // img: "img8.jpg",
    },
    {
        link: "https://www.cyber-waste.io/",
        txt: "Cyber Waste",
        img: "img9.jpg",
    },
    {
        link:
            "https://exposingtheinvisible.org/en/articles/seeing-the-world-through-googles/",
        txt: "Seeing the world through Google's eyes",
        img: "img10.jpg",
    },
    {
        link: "https://www.datadetoxkit.org/en/home",
        txt: "Data Detox Kit",
        img: "img11.jpg",
    },
    {
        link:
            "https://bigthink.com/strange-maps/germany-street-view?rebelltitem=6#rebelltitem6",
        txt: "Why Germany has no Street View",
        img: "img12.jpg",
    },
    {
        link:
            "https://www.dw.com/en/berlin-court-rules-google-street-view-is-legal-in-germany/a-14929074",
        txt: "Berlin court rules Google Street View is legal in Germany",
        img: "img13.jpg",
    },
    {
        link:
            "https://manhattanneighbors.org/real-estate-survey-results-neighborhood-cell-towers-antennas-do-they-impact-a-propertys-desirability/",
        txt: "Real Estate Survey: Neighborhood Antennas",
        img: "img14.jpg",
    },
    {
        link: "https://www.coveillance.org/",
        txt: "Coveillance",
        // img: "img15.jpg",
    },
    {
        link:
            "https://www.quora.com/How-harmful-is-living-staying-under-some-mobile-phone-towers",
        txt: "How harmful is living/staying under some mobile phone towers?",
        // img: "img16.jpg",
    },
    {
        link:
            "https://www.nytimes.com/2009/01/11/nyregion/long-island/11RcellWE.html",
        txt: "Cellphone Towers Are on the Rise, but Not Always Welcome",
        img: "img17.jpg",
    },
    {
        link: "https://berlinvsamazon.com/",
        txt: "Amazon Tower in Berlin verhindern - Berlin Vs Amazon",
        img: "img18.jpg",
    },
    {
        link:
            "https://nextcity.org/daily/entry/gig-economy-brookings-data-freelancers",
        txt: "What Gig Economy Data Reveal About Changing Urban Life",
        img: "img19.jpg",
    },
    {
        link:
            "https://urbaninspiration.nl/kreuzberg-doesnt-want-google-campus/",
        txt: "Kreuzberg doesn't want Google Campus",
        img: "img20.jpg",
    },
    {
        link: "https://project-plus.eu/",
        txt: "PLUS – Platform Labour in Urban Spaces",
        // img: "img21.jpg",
    },
    {
        link: "https://www.archplus.net/home/archiv/ausgabe/46,230,1,0.html",
        txt: "Datatopia",
        img: "img22.jpg",
    },
    {
        link:
            "https://arstechnica.com/information-technology/2016/11/private-microwave-networks-financial-hft/",
        txt: "The secret world of microwave networks",
        img: "img23.jpg",
    },
    {
        link:
            "https://www.leonardo.info/opportunity/a-vision-for-change-a-new-media-architecture-uniting-the-arts-and-sciences?fbclid=IwAR1N3flI8OHqY6IL9VaR_yoe0GekZuarP4LcVe7gGVAQ54wYdUamBGIzjCw",
        txt:
            "A Vision For Change: A New Media Architecture Uniting The Arts And Sciences",
        // img: "img24.jpg",
    },
    {
        link: "https://hakin9.org/katana-a-python-tool-for-google-hacking/",
        txt: "Tool For Google Hacking",
        img: "img25.jpg",
    },
    {
        link: "http://www.bldgblog.com/2013/02/optical-calibration-targets/",
        txt: "Optical Calibration Targets",
        img: "img26.jpg",
    },
    {
        link:
            "http://www.clui.org/newsletter/winter-2013/photo-calibration-targets",
        txt: "The Center for Land Use Interpretation",
        img: "img27.jpg",
    },
    {
        link:
            "https://theprint.in/defence/chinese-satellite-centre-tibet-track-blind-indian-eyes-sky/39543/",
        txt:
            "A Chinese centre in Tibet could blind or destroy Indian satellites",
        img: "img28.jpg",
    },
    {
        link:
            "https://www.nature.com/news/satellite-snafu-masked-true-sea-level-rise-for-decades-1.22312?WT.mc_id=TWT_NatureNews&sf98568619=1",
        txt: "Satellite snafu masked true sea-level rise for decades",
        img: "img29.jpg",
    },
    {
        link:
            "https://gizmodo.com/zooming-in-on-satellite-calibration-targets-in-the-ariz-1560801481?utm_campaign=socialflow_gizmodo_twitter&utm_source=gizmodo_twitter&utm_medium=socialflow",
        txt:
            "Zooming-In On Satellite Calibration Targets in the Arizona Desert",
        img: "img30.jpg",
    },
    {
        link:
            "https://personalpages.manchester.ac.uk/staff/m.dodge/cybergeography/atlas/more_isp_maps.html",
        txt: "An Atlas of Cyberspaces",
        img: "img31.gif",
    },
    {
        link:
            "https://www.metropolitiques.eu/New-York-Tech-Dossier-The-Dark-Side-of-New-York-s-Tech-Economy.html",
        txt: "New York Tech Dossier: The Dark Side of New York’s Tech Economy",
        // img: "img32.jpg",
    },
    {
        link:
            "https://www.e-flux.com/journal/04/68569/religion-in-the-age-of-digital-reproduction/",
        txt: "Religion in the Age of Digital Reproduction",
        // img: "img33.jpg",
    },
    {
        link: "https://www.project-sherpa.eu/",
        txt: "Shaping the Ethical Dimensions of Smart Information Systems",
        // img: "img34.jpg",
    },
    {
        link: "https://monde-diplomatique.de/artikel/!5710105",
        txt: "Race for 5G",
        img: "img35.jpg",
    },
    {
        link:
            "https://news.mit.edu/2018/study-finds-gender-skin-type-bias-artificial-intelligence-systems-0212?link_id=4&can_id=&source=email-see-if-facial-recognition-is-happening-in-your-town-3&email_referrer=email_582920&email_subject=see-if-facial-recognition-is-happening-in-your-town",
        txt:
            "Study finds gender and skin-type bias in commercial artificial-intelligence systems",
        // img: "img36.jpg",
    },
    {
        link:
            "https://www.vice.com/en_us/article/zmp5pj/police-body-cam-company-says-it-wont-use-facial-recognition-tech-for-now?link_id=3&can_id=&source=email-see-if-facial-recognition-is-happening-in-your-town-3&email_referrer=email_582920&email_subject=see-if-facial-recognition-is-happening-in-your-town",
        txt:
            "Police Body Cam Company Says it Won't Use Facial Recognition (For Now)",
        img: "img37.jpg",
    },
    {
        link:
            "https://www.banfacialrecognition.com/map/?link_id=0&can_id=&source=email-see-if-facial-recognition-is-happening-in-your-town-3&email_referrer=email_582920&email_subject=see-if-facial-recognition-is-happening-in-your-town",
        txt: "Ban Facial Recognition",
        // img: "img38.jpg",
    },
    {
        link:
            "https://www.businessinsider.de/china-social-credit-system-punishments-and-rewards-explained-2018-4?r=US&IR=T",
        txt: "China social credit system, punishments and rewards explained",
        img: "img39.jpg",
    },
    {
        link:
            "https://www.socialmedialawbulletin.com/2015/01/who-owns-your-instagram-content/",
        txt: "Who owns your Instagram content?",
        // img: "img40.jpg",
    },
    {
        link:
            "https://www.faz.net/aktuell/feuilleton/debatten/the-digital-debate/shoshana-zuboff-secrets-of-surveillance-capitalism-14103616.html?printPagedArticle=true#pageIndex_1",
        txt: "Secrets of Surveillance Capitalism",
        img: "img41.jpg",
    },
    {
        link:
            "https://www.deutschlandfunk.de/urbanes-leben-die-digitalisierung-veraendert-die-stadt.740.de.mhtml?dram%3Aarticle_id=450808&xtor=AD-251-%5B%5D-%5B%5D-%5B%5D-%5Bdlf-mobil%5D-%5B%5D-%5B%5D&fbclid=IwAR05zpnnIEIagOtDfLUJ6ijKKpXNCfv_KSmO_syVo57fJaWKua_JeSxg79s",
        txt: "Urbanes Leben - Die Digitalisierung verändert die Stadt",
        img: "img42.jpg",
    },
    {
        link:
            "https://architizer.com/blog/inspiration/industry/the-architecture-of-surveillance/",
        txt: "The Architecture Of Surveillance",
        img: "img43.jpg",
    },
    {
        link:
            "https://www.amazon.com/Surveillance-Architecture-Control-Discourses-Spatial/dp/3030003701/ref=sr_1_1?keywords=Surveillance%2C+Architecture+and+Control&qid=1581350734&s=books&sr=1-1",
        txt:
            "Surveillance, Architecture and Control: Discourses on Spatial Culture",
        // img: "img44.jpg",
    },
    {
        link:
            "https://www.clui.org/newsletter/winter-2013/photo-calibration-targets",
        txt: "The Center for Land Use Interpretation",
        // img: "img45.jpg",
    },
    {
        link:
            "https://www.gearthhacks.com/downloads/Photo-Calibration-Targets/",
        txt: "Photo Calibration Targets - Google Earth Hacks",
        // img: "img46.png",
    },
    {
        link:
            "https://www.theblaze.com/news/2013/02/18/what-are-these-strange-barcode-like-images-visible-in-aerial-pictures-of-the-u-s",
        txt:
            "What Are These Strange Barcode-Like Images Visible in Aerial Pictures of the U.S.? -",
        // img: "img47.jpg",
    },
    {
        link: "https://mapfight.appspot.com/",
        txt: "MapFight - compare country size",
        // img: "img48.jpg",
    },
];
